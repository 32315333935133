a,
i {
  text-decoration: none;
}

ul {
  list-style: none;
}

p {
  font-size: 1.8rem;
  line-height: 1.5em;
}

img {
  width: 100%;
}

.contentContainer {
  padding: 100px 0;
}

@media only screen and (max-width: 550px) {
  .contentContainer {
    padding-right: 20px;
    padding-left: 20px;
  }
}

.mainContent {
  height: 100vh;
  display: grid;
  grid-template-columns: 4fr 1fr 1fr 1fr 1fr;
  grid-auto-flow: dense;
  transition: all 0.5s ease-in;
}

.contentSection {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
}

/* .apfSteel {
  grid-column-start: 1;
  grid-column-end: 4;
} */

/* .apfTires:hover {
  grid-template-columns: 1fr 4fr 1fr 1fr;
} */

.activeApfSteel {
  grid-template-columns: 1fr 4fr 1fr 1fr 1fr;
  /* transition: grid-template-columns 0.5s ease-in; */
}

.activeApfTires {
  grid-template-columns: 1fr 1fr 4fr 1fr 1fr;
  /* transition: grid-template-columns 0.5s ease-in; */
}

.activeApfFittings {
  grid-template-columns: 1fr 1fr 1fr 4fr 1fr;
}

.activeApfContact {
  grid-template-columns: 1fr 1fr 1fr 1fr 4fr;
}

.apfGroup {
  background-image: url("./assets/apfgroupbg.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.apfSteel {
  background-image: url("./assets/apfsteelbg.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.apfTires {
  background-image: url("./assets/apftiresbg.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.apfFittings {
  background-image: url("./assets/apffittingbg.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.canadianLift {
  background-image: url("./assets/canadianLift.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.apfContact {
  /* background-image: url("./assets/contactbg.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat; */
  min-height: unset !important;
  flex-direction: column;
  justify-content: flex-start !important;
  gap: 30px;
}

.apfContact h3 {
  font-size: 40px;
  font-weight: 600;
}

.overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0, 0.5);
  z-index: 0;
}

.logoContainer {
  z-index: 999999;
  position: relative;
}

.logoContainer a {
  position: absolute;
  width: 100%;
  height: 100%;
  display: none;
}

.activeApfTires .apfTires .logoContainer a {
  display: block !important;
  top: 0;
}

.activeApfSteel .apfSteel .logoContainer a {
  display: block !important;
  top: 0;
}

.activeApfFittings .apfFittings .logoContainer a {
  display: block !important;
  top: 0;
}

a.btnLink {
  background: #fff;
  z-index: 1;
  font-size: 20px;
  text-decoration: none;
  color: #9c1513;
  padding: 8px 30px;
  border-radius: 20px;
  font-weight: 600;
}

@media only screen and (max-width: 600px) {
  .contactDetail > div > div {
    flex-direction: column !important;
  }

  .contactDetail > div > div > div:nth-of-type(2) {
    margin-top: 30px;
  }

  .mainContent {
    grid-template-columns: unset;
    grid-template-rows: 4fr 1fr 1fr 1fr 1fr;
  }

  .activeApfSteel {
    grid-template-rows: 1fr 4fr 1fr 1fr 1fr;
  }

  .activeApfTires {
    grid-template-rows: 1fr 1fr 4fr 1fr 1fr;
  }

  .activeApfFittings {
    grid-template-rows: 1fr 1fr 1fr 4fr 1fr;
  }

  .activeApfContact {
    grid-template-rows: 1fr 1fr 1fr 1fr 4fr;
  }

  .closed img,
  .apfContact h2 {
    transform: rotate(0deg) !important;
  }

  .apfContact h2 {
    margin: 50px 0;
  }

  .contentSection ul li {
    font-size: 20px;
    margin-bottom: 10px;
  }

  .contentSection ul {
    /* margin-bottom: 30px; */
    padding: 0 20px;
    margin: 0 0 30px;
  }
}

.contentSection img {
  max-width: 200px;
  z-index: 1;
}

.contentSection .logoContainer a {
  color: #000;
}

.closed img {
  transform: rotate(89deg);
  transition: all 0.2s ease-in;
}

.activeApfSteel .apfSteel img {
  transform: rotate(0deg);
}

.activeApfTires .apfTires img {
  transform: rotate(0deg);
}

.activeApfFittings .apfFittings img {
  transform: rotate(0deg);
}

.activeApfContact .apfContact h2 {
  transform: rotate(0deg);
}

.active .apfGroup img {
  transform: rotate(89deg);
  transition: all 0.2s ease-in;
}

.apfContact h2 {
  color: #fff;
  font-weight: bold;
  font-size: 41px;
  transform: rotate(89deg);
  z-index: 1;
  transition: all 0.2s ease-in;
}

.hideText {
  display: none;
  z-index: 1;
  text-align: center;
}

.activeApfSteel .aboutApfSteel {
  display: block;
}

.activeApfTires .aboutApfTires {
  display: block;
}

.activeApfFittings .aboutApfFittings {
  display: block;
}

.activeApfContact .aboutApfContact {
  display: block;
}

.contentSection ul {
  padding: 0;
  margin-bottom: 30px;
}

.contentSection ul li {
  font-size: 28px;
  color: #fff;
  font-weight: 600;
  text-align: center;
}

.contentSection ul li svg {
  fill: #9f211f;
  margin-right: 10px;
}

.aboutApfContact a {
  text-decoration: none;
  color: #fff;
}

/* @keyframes gridchange {
  0% { grid-template-columns: 4fr 1fr 1fr 1fr 1fr }
  100% { grid-template-columns: 1fr 4fr 1fr 1fr 1fr }
}

.changing {
  animation: gridchange 5s;
  animation-name: slidein;
  animation-timing-function: ease-in;
} */

/* New CSS */

.bannerSection {
  background-image: url("./assets/apfgroupbg.jpg");
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 90vh;
  display: flex;
  align-items: center;
  position: relative;
}

.bannerSection > div:nth-of-type(1) {
  z-index: 4;
  position: relative;
}

.bannerSection > div:nth-of-type(1) > div > div {
  text-align: center;
}

.bannerSection > div:nth-of-type(1) > div > div img {
  max-width: 500px;
}

/* .apfSection {
  padding-block: 100px;
}

.altApfSection {
  padding-block: 50px;
} */

.companySection {
  padding-block: 100px;
}

.apfSection > div > div > div:nth-of-type(1) {
  min-height: 660px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.apfSection > div > div > div:nth-of-type(1) img {
  max-width: 250px;
  z-index: 4;
}

.apfSection > div > div > div:nth-of-type(2) {
  padding: 60px;
}

.apfSection > div > div > div:nth-of-type(2) h2 {
  font-size: 50px;
}

.apfSection > div > div > div:nth-of-type(2) p {
  margin: 30px 0 0;
  font-size: 18px;
}

.apfSection > div > div > div:nth-of-type(2) ul {
  list-style: none;
  margin-block: 30px !important;
  display: flex;
  gap: 30px;
}

.apfSection > div > div > div:nth-of-type(2) ul li {
  font-size: 16px;
  position: relative;
}

.apfSection > div > div > div:nth-of-type(2) ul li:after {
  content: "|";
  color: #000;
  position: absolute;
  top: 50%;
  right: -15px;
  transform: translateY(-50%);
}

.apfSection > div > div > div:nth-of-type(2) ul li a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  color: #000;
  text-decoration: none !important;
}

.altApfSection > div > div > div:nth-of-type(2) {
  min-height: 660px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.altApfSection > div > div > div:nth-of-type(2) img {
  max-width: 250px;
  z-index: 4;
}

.altApfSection > div > div > div:nth-of-type(1) {
  padding: 60px;
}

.altApfSection > div > div > div:nth-of-type(1) h2 {
  font-size: 50px;
}

.altApfSection > div > div > div:nth-of-type(1) p {
  margin: 30px 0 0;
  font-size: 18px;
}

.altApfSection > div > div > div:nth-of-type(1) ul {
  list-style: none;
  margin-block: 30px !important;
  display: flex;
  gap: 30px;
}

.altApfSection > div > div > div:nth-of-type(1) ul li {
  font-size: 16px;
  position: relative;
}

.altApfSection > div > div > div:nth-of-type(1) ul li:after {
  content: "|";
  color: #000;
  position: absolute;
  top: 50%;
  right: -15px;
  transform: translateY(-50%);
}

.altApfSection > div > div > div:nth-of-type(1) ul li a {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  color: #000;
  text-decoration: none !important;
}

.contactDetail {
  margin-top: 50px;
}

.contactDetail > div > div > div:nth-of-type(1) ul {
  list-style: none !important;
  padding-left: 0px !important;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

.contactDetail > div > div {
  justify-content: center;
}

.contactDetail > div > div > div:nth-of-type(1) {
  background-color: #661514;
  border-radius: 20px;
}

.contactDetail > div > div > div:nth-of-type(1) h2,
.contactDetail > div > div > div:nth-of-type(1) ul li a {
  color: #fff;
}

.contactDetail > div > div > div:nth-of-type(1) hr {
  border-color: #fff !important;
}

.contactDetail > div > div > div:nth-of-type(1) ul li:after {
  display: none;
}

.contactDetail > div > div > div:nth-of-type(1) ul li {
  flex: 1 0 500px;
}

/* .contactDetail > div > div > div:nth-of-type(1) ul li a {
  
} */

.bottomFooter {
  background-color: #f8f8f8;
  padding-block: 30px;
}

.bottomFooter > div > div > div p {
  color: #000;
  margin: 0;
  font-size: 16px;
}

.bottomFooter > div > div > div:nth-of-type(2) p {
  text-align: right;
}

.bottomFooter > div > div > div:nth-of-type(2) a {
  border-bottom: 1px solid #000;
  color: var(--primaryColor);
  text-decoration: none !important;
}

@media only screen and (max-width: 600px) {
  .apfSection > div > div > div:nth-of-type(2),
  .altApfSection > div > div > div:nth-of-type(1) {
    padding: 40px 20px;
  }

  .apfSection > div > div > div:nth-of-type(2) ul {
    flex-wrap: wrap;
  }

  .contactDetail > div > div > div:nth-of-type(1) ul li {
    flex: unset;
  }

  .contactDetail > div > div > div:nth-of-type(1) h2 {
    font-size: 40px;
  }

  .contactDetail {
    margin-top: 0px;
    padding: 0 10px !important;
  }

  .apfSection,
  .altApfSection {
    padding-block: 50px;
  }

  .altApfSection > div > div {
    flex-direction: column-reverse;
  }

  .bottomFooter > div > div {
    padding-inline: 20px;
    row-gap: 20px;
  }

  .bottomFooter > div > div > div p {
    text-align: center !important;
  }
}
