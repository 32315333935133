@import "@fontsource/roboto";
@import "@fontsource-variable/playfair-display";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

ul {
  list-style: none;
  padding: 0 !important;
  margin: 0 !important;
  font-family: "Roboto", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Playfair Display", serif;
}

.primaryBtn {
  font-size: 18px;
  font-weight: 500;
  width: auto;
  padding: 16px 32px;
  background-color: #661514;
  color: #fff !important;
  border-radius: 50px;
  outline: none;
  border: 2px solid #661514;
  letter-spacing: 2.5px;
  transition: all 0.3s ease-in-out;
  display: inline-block;
  text-align: center;
  text-decoration: none !important;
}

.primaryBtn:hover {
  background-color: transparent;
  color: #661514 !important;
}
